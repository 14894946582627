<template>
  <div
    v-if="
      car.supplier?.logo &&
      auction.activeStatusesNames?.includes('deliverer_logo')
    "
  >
    <img
      :src="car.supplier.logo"
      :alt="car.supplier.label"
      class="max-h-[50px]"
    />
  </div>
</template>

<script setup lang="ts">
import type { AuctionCar } from '@autobid/ui/types/Car'
import { useAuction } from '@autobid/ui/composables/useAuction'

type Props = {
  car: AuctionCar
}

const props = defineProps<Props>()

const { auction } = useAuction(props.car.auctionId)
</script>
