<template>
  <Popover
    v-if="additionalInfo?.length"
    v-slot="{ open, close }"
    class="relative text-xs !text-black md:text-sm"
    as="div"
  >
    <PopoverButton
      class="rounded-full border-2 border-[#FABD40] bg-[#FED56D] py-1 sm:w-64"
      @mouseover="(e) => hoverPopover(e, open)"
      @mouseleave="closePopover(close)"
      @click="(e) => clickPopover(e, open)"
    >
      <p class="font-semibold">
        <span class="font-bold uppercase">{{
          $t('addition-info.data-addition')
        }}</span>
        ({{ additionalInfo.length }})
      </p>
      <p class="text-xs">
        {{ $t('addition-info.updated') }}
        {{
          `${formatStartDate(additionalInfo[0].additionTime, {
            showFullDate: true,
            $t
          })}`
        }}
      </p>
    </PopoverButton>

    <div ref="parent">
      <PopoverPanel
        class="absolute z-20 mt-1 w-max bg-white shadow-md"
        @mouseover.prevent="popoverHover = true"
        @mouseleave.prevent="closePopover(close)"
      >
        <header class="bg-[#FED56D] px-4 py-2 font-bold uppercase">
          {{ $t('addition-info.data-addition') }}
        </header>
        <ol class="ml-4 w-full list-decimal space-y-2 p-2">
          <li
            v-for="item in sortedAdditionalInfo"
            :key="item.id"
            class="w-[98%]"
          >
            <h3 class="mb-1 max-w-xs font-semibold">
              {{ $t('addition-info.data-addition') }}
              {{
                formatStartDate(item.additionTime, { showFullDate: true, $t })
              }}
            </h3>
            <p class="w-full max-w-xs">
              {{ `${item.categoryLabel}: ` }}{{ item.content }}
            </p>
          </li>
        </ol>
      </PopoverPanel>
    </div>
  </Popover>
</template>

<script setup lang="ts">
import { formatStartDate } from '@autobid/ui/utils/auctions/formatStartDate'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import { useCarAdditionalInfo } from '@autobid/ui/composables/car/useCarAdditionalInfo'
import { usePopoverHover } from '@autobid/ui/composables/usePopoverHover'

const [parent] = useAutoAnimate({ duration: 150 })

type Props = {
  carId: number
}
const props = defineProps<Props>()

const { additionalInfo, sortedAdditionalInfo } = useCarAdditionalInfo(
  props.carId
)

const { hoverPopover, closePopover, clickPopover, popoverHover } =
  usePopoverHover()
</script>
