import type { MaybeRef } from 'vue'
import { useGetCar } from '../useCar'

export function useCarAdditionalInfo(carId: MaybeRef<number>) {
  const { getCar } = useGetCar()
  const car = computed(() => getCar(unref(carId)))
  const additionalInfo = computed(() => car.value.dataAddition)
  const sortedAdditionalInfo = computed(() => {
    return additionalInfo.value
      ?.slice()
      .sort(
        (a, b) =>
          new Date(a.additionTime).getTime() -
          new Date(b.additionTime).getTime()
      )
  })

  return { additionalInfo, sortedAdditionalInfo }
}
